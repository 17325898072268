import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/invoice', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/invoice/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateInvoice(ctx, { invoiceData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/invoice/${id}`, invoiceData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/invoice/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addInvoice(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/invoice', invoiceData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/invoice/${id}/download`, {
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
